import { Input as AntdInput } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-form-item-label {
    padding-bottom: 4px;
  }
`

export const Input = styled(AntdInput)`
  height: 40px;
  margin: 0;

  .ant-input-prefix {
    color: ${({ theme }) => theme.colors.brand};
  }
`
export const InputPassword = styled(AntdInput.Password)`
  height: 40px;
  margin: 0;

  .ant-input-prefix {
    color: ${({ theme }) => theme.colors.brand};
  }
`
