import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import type { User, UserFilters } from '..'

import {
  ActiveSorting,
  DEFAULT_ACTIVE_SORTING,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION,
  ListResponse,
  PaginationExpression,
  getFilterExpression,
  handleActiveSorting,
} from '@/common'
import { api } from '@/services'

export async function fetchAllUsers(filters: UserFilters = {}) {
  const filterExpression = getFilterExpression(filters)

  const { data } = await api.post<ListResponse<User[]>>('/user/list', {
    filter_expression: filterExpression,
    pagination_expression: {
      page: DEFAULT_PAGE,
      per_page: 0,
    },
  })

  return data
}

async function fetchUsersList(
  filters: UserFilters,
  sorting: ActiveSorting,
  pagination: PaginationExpression,
  isListSimple: boolean = false,
) {
  if (
    filters.user_situation &&
    filters.active &&
    filters.enabled &&
    !filters.user_situation.value
  ) {
    delete filters.user_situation
    delete filters.active
    delete filters.enabled
  }

  if (filters.user_situation?.value === 'excluded') {
    filters.active = { value: false, comparator: 'eq' }
    filters.enabled = { value: false, comparator: 'eq' }
  }

  const filterExpression = getFilterExpression(filters)

  const activeSorting = handleActiveSorting(sorting)

  const endpoint = isListSimple ? '/user/list-simple' : '/user/list'

  const { data } = await api.post<ListResponse<User[]>>(endpoint, {
    filter_expression: filterExpression,
    pagination_expression: {
      page: pagination.page || DEFAULT_PAGE,
      per_page: pagination.per_page === undefined ? DEFAULT_PAGE_SIZE : pagination.per_page,
    },
    order_by_expression: activeSorting,
  })

  return data
}

async function fetchUser(userId: string) {
  const { data } = await api.get<User>(`/user/${userId}`)

  return data
}

export const userQueries = {
  all: () => ['users'] as const,
  lists: () => [...userQueries.all(), 'list'] as const,
  list: (
    filters: any = {},
    sorting: ActiveSorting = DEFAULT_ACTIVE_SORTING,
    pagination: PaginationExpression = DEFAULT_PAGINATION,
  ) =>
    queryOptions({
      queryKey: [...userQueries.lists(), filters, sorting, pagination],
      queryFn: () => fetchUsersList(filters, sorting, pagination),
      placeholderData: keepPreviousData,
    }),
  simpleList: (
    filters: any = {},
    sorting: ActiveSorting = DEFAULT_ACTIVE_SORTING,
    pagination: PaginationExpression = DEFAULT_PAGINATION,
  ) =>
    queryOptions({
      queryKey: [...userQueries.lists(), 'simple', filters, sorting, pagination],
      queryFn: () => fetchUsersList(filters, sorting, pagination, true),
      placeholderData: keepPreviousData,
    }),
  details: () => [...userQueries.all(), 'details'] as const,
  detail: (userId: string) =>
    queryOptions({
      queryKey: [...userQueries.details(), userId],
      queryFn: () => fetchUser(userId),
      placeholderData: keepPreviousData,
    }),
}
