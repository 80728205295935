import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Form, Table, Tag } from 'antd'

import * as S from './styles'

import { useDebounce } from '@/common'
import { User, UserSituation, userQueries } from '@/features/users'

interface Props {
  groupName: string
  initialData?: string[]
}

export const GroupFormFourthStep = ({ groupName, initialData }: Props) => {
  const form = Form.useFormInstance()
  const { data: users, isLoading } = useQuery(
    userQueries.simpleList(
      {
        active: {
          value: true,
          comparator: 'eq',
        },
      },
      undefined,
      { page: 1, per_page: 0 },
    ),
  )

  const [searchValue, setSearchValue] = useState('')
  const [tableData, setTableData] = useState<User[]>(users?.results || [])

  const [selectedUsers, setSelectedUsers] = useState<string[]>(() =>
    initialData ? initialData : [],
  )

  const debouncedSearch = useDebounce(searchValue)

  useEffect(() => {
    let filteredUsers

    if (searchValue === '') {
      filteredUsers = users?.results
    } else {
      filteredUsers = users?.results.filter(
        (user) =>
          user.name.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
          user.name.toLowerCase() === debouncedSearch.toLowerCase(),
      )
    }

    setTableData(filteredUsers as User[])
  }, [debouncedSearch, searchValue, users])

  function handleSelectUser(selectedUserIds: string[]) {
    const updatedCheckedUsers = new Set(selectedUsers)

    tableData.forEach((user) => {
      if (selectedUserIds.includes(user.id)) {
        updatedCheckedUsers.add(user.id)
      } else if (updatedCheckedUsers.has(user.id)) {
        updatedCheckedUsers.delete(user.id)
      }
    })

    const uniqueCheckedUsers = Array.from(updatedCheckedUsers)

    setSelectedUsers(uniqueCheckedUsers)
    form.setFieldValue('group_users_id', uniqueCheckedUsers)
  }

  const columns = [
    // {
    //   title: '',
    //   dataIndex: '',
    //   key: '',
    // },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Status',
      dataIndex: 'user_situation',
      key: 'user_situation',

      render: (status: UserSituation) => {
        if (status === 'Ativo') {
          return <Tag color="green">Ativo</Tag>
        }

        if (status === 'Inativo') {
          return <Tag>Inativo</Tag>
        }

        if (status === 'Excluído') {
          return <Tag color="red">Excluído</Tag>
        }

        return <Tag>Indefinido</Tag>
      },
    },
  ]

  return (
    <S.Container $hasError={form.getFieldError('group_users_id').length > 0}>
      <S.SearchInput
        placeholder="Pesquisar usuário"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        suffix={<S.SearchIcon />}
      />

      <Form.Item
        name="group_users_id"
        rules={[
          {
            required: true,
            message: 'Selecione pelo menos um usuário',
          },
        ]}
      >
        <Table
          size="small"
          virtual
          scroll={{ x: 0 }}
          columns={columns}
          pagination={false}
          dataSource={tableData}
          title={() => (
            <S.TableHeader>
              <span>
                Grupo: <strong>{groupName}</strong>
              </span>
            </S.TableHeader>
          )}
          rowKey={(record) => record.id}
          loading={isLoading}
          rowSelection={{
            selectedRowKeys: selectedUsers,
            onChange: (_, selectedRows: User[]) =>
              handleSelectUser(selectedRows.map((user) => user.id)),
            hideSelectAll: true,
          }}
        />
      </Form.Item>
    </S.Container>
  )
}
