import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Form, TableColumnsType, Tag } from 'antd'

import { TransferTable } from '../../..'

import { GroupSelectedPermissions, GroupSelectedSystems } from '@/features/groups'
import { roleQueries, type Role } from '@/features/roles'

interface Props {
  groupName: string
  groupSystems: GroupSelectedSystems[]
  initialData: {
    leftTable: GroupSelectedPermissions[]
    rightTable: GroupSelectedPermissions[]
  }
}

const columns: TableColumnsType<GroupSelectedPermissions> = [
  {
    title: 'Permissões',
    dataIndex: 'title',
    key: 'title',
    width: 300,
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
    width: 200,
  },
  {
    title: 'Status',
    dataIndex: 'active',
    key: 'active',
    render: (active: boolean, record: any) => {
      if (!record.children) return null

      return active ? <Tag color="green">Ativa</Tag> : <Tag color="red">Inativa</Tag>
    },
  },
]

export const GroupFormThirdStep = ({ groupName, groupSystems, initialData }: Props) => {
  const form = Form.useFormInstance()
  const {
    data: roles,
    isLoading,
    isFetched,
  } = useQuery(
    roleQueries.list(
      {
        system: {
          system_name: {
            value: groupSystems.map((system) => system.label),
            comparator: 'in',
          },
        },
      },
      undefined,
      {
        page: 1,
        per_page: 0,
      },
    ),
  )

  const [leftTableData, setLeftTableData] = useState<GroupSelectedPermissions[]>(() =>
    initialData?.leftTable.length > 0 ? initialData.leftTable : [],
  )
  const [rightTableData, setRightTableData] = useState<GroupSelectedPermissions[]>(() => {
    const selectedPermissions = form.getFieldValue(
      'selectedPermissions',
    ) as GroupSelectedPermissions[]

    // if we already have selected permissions, and dont have initial values (isn't editing) use the previous selected permissions
    if (
      selectedPermissions &&
      selectedPermissions.length > 0 &&
      (!initialData.rightTable || initialData.rightTable.length <= 0)
    ) {
      return selectedPermissions
    }

    return initialData?.rightTable.length > 0 ? initialData.rightTable : []
  })

  useEffect(() => {
    if (isFetched) {
      const rolesData = formatRoles(roles?.results as Role[])

      let updatedLeftTableData

      // left table starts with formated roles
      updatedLeftTableData = rolesData

      // if initial data is passed, update left table with it
      if (initialData && initialData.leftTable.length > 0) {
        updatedLeftTableData = initialData.leftTable
      }

      // if right table initial data is passed, remove right table data from left table
      if (initialData && initialData.rightTable.length > 0) {
        const roleById = new Map(updatedLeftTableData.map((item) => [item.key, { ...item }]))

        initialData.rightTable.forEach((role) => {
          const leftTableItem = roleById.get(role.key)

          if (leftTableItem) {
            // filter out permissions that are already in the right table
            const filteredPermissions = leftTableItem.role_permissions.filter((perm) => {
              const isInRightTable = !!role.children.find((p) => p.title === perm.title)

              return !isInRightTable
            })

            // update left table item with filtered permissions
            leftTableItem.children = filteredPermissions.map((perm) => ({
              key: `${role.pk}.${perm.id}`,
              title: perm.title,
              description: perm.description,
              value: perm.value,
              custom: perm.custom,
            }))

            if (leftTableItem.children.length === 0) {
              roleById.delete(role.key)
            }
          }
        })

        updatedLeftTableData = Array.from(roleById.values())
      }

      setLeftTableData(updatedLeftTableData as GroupSelectedPermissions[])
    }
  }, [isFetched, initialData, roles])

  // update form values when table data changes
  useEffect(() => {
    form.setFieldValue('selectedPermissions', rightTableData)
    form.setFieldValue('rolesLeftTable', leftTableData)

    if (rightTableData.length > 0) {
      form.validateFields(['selectedPermissions'])
    }
  }, [rightTableData, form, leftTableData])

  function formatRoles(roles: Role[]) {
    return roles?.map((role) => ({
      ...role,
      key: role.pk,
      title: role.title,
      description: role.description,
      children: role.role_permissions.map((perm) => {
        return {
          key: `${role.pk}.${perm.id}`,
          title: perm.title,
          description: perm.description,
          value: perm.value,
          custom: perm.custom,
        }
      }),
    })) as GroupSelectedPermissions[]
  }

  const tableTitles = ['Lista de permissões', 'Permissões do grupo'] satisfies [string, string]

  return (
    <Form.Item
      name="selectedPermissions"
      rules={[
        {
          required: true,
          message: 'Transfira pelo menos uma permissão para o grupo.',
        },
      ]}
    >
      <TransferTable<GroupSelectedPermissions>
        tableTitles={tableTitles}
        isLoading={isLoading}
        leftColumns={columns}
        rightColumns={columns}
        leftTableData={{
          state: leftTableData,
          setState: setLeftTableData,
          title: groupSystems.map((system) => system.label)?.join(', '),
        }}
        rightTableData={{
          state: rightTableData,
          setState: setRightTableData,
          title: groupName,
        }}
      />
    </Form.Item>
  )
}
