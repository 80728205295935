import { useNavigate } from 'react-router-dom'

import { Button } from 'antd'

import { PageTitle } from '@/components'
import {
  ForgotPasswordForm,
  ForgotPasswordFormData,
  useForgotPasswordCodeMutation,
} from '@/features/auth'
import { AccessLayout } from '@/layouts'

export const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const forgotPasswordMutation = useForgotPasswordCodeMutation()

  function handleSendCode({ email }: ForgotPasswordFormData) {
    forgotPasswordMutation.mutate({ email: email.trim() })
  }

  return (
    <AccessLayout>
      <PageTitle title="Recuperar senha" />

      <ForgotPasswordForm onSubmit={handleSendCode} isLoading={forgotPasswordMutation.isPending} />

      <Button
        block
        type="link"
        onClick={() => navigate('/auth')}
        disabled={forgotPasswordMutation.isPending}
      >
        Voltar
      </Button>
    </AccessLayout>
  )
}
