import { useNavigate } from 'react-router-dom'

import { Button } from 'antd'

import * as S from './styles'

import { LoginForm, LoginFormData, useSignInMutation } from '@/features/auth'
import { AccessLayout } from '@/layouts'

const items = [
  {
    label: 'Login',
    key: 'login',
  },
]

export function LoginPage() {
  const navigate = useNavigate()
  const signInMutation = useSignInMutation()

  async function handleLogin({ email, password, mfa }: LoginFormData) {
    // console.log({ email, password, mfa })

    signInMutation.mutate({ email: email.trim(), password: password.trim(), mfa_type: mfa })
  }

  return (
    <AccessLayout title="Login">
      <S.Container>
        <S.Title items={items} />

        <LoginForm onSubmit={handleLogin} isLoading={signInMutation.isPending} />

        <Button
          block
          type="link"
          onClick={() => navigate('/auth/forgot-password')}
          disabled={signInMutation.isPending}
        >
          Esqueci minha senha
        </Button>
      </S.Container>
    </AccessLayout>
  )
}
