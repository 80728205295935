import { useMutation, useQueryClient } from '@tanstack/react-query'

import { UserFormData, UserSituation, userQueries } from '../..'

import { api } from '@/services'

export const useCreateUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: UserFormData) => {
      return api.post('/user', data)
    },
    onMutate: (data) => {
      queryClient.cancelQueries({ queryKey: userQueries.all() })

      const cachedUsersList = queryClient.getQueryData(userQueries.list().queryKey)

      if (cachedUsersList) {
        const updatedUserList = [
          ...cachedUsersList.results,
          {
            ...data,
            id: crypto.randomUUID(),
            status: '',
            nickname: data.nickname || '',
            last_sign_in: '',
            created_at: '',
            updated_at: '',
            user_groups: [],
            enabled: true,
            active: true,
            user_situation: 'Ativo' as UserSituation,
          },
        ]

        queryClient.setQueryData(userQueries.list().queryKey, {
          ...cachedUsersList,
          results: updatedUserList,
        })
      }

      return cachedUsersList
    },
    onError: (error, _, cachedUsers) => {
      console.error(error)

      if (cachedUsers) {
        queryClient.setQueryData(userQueries.list().queryKey, cachedUsers)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: userQueries.all() }),
  })
}
