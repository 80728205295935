import { Navigate, useSearchParams } from 'react-router-dom'

import { toast } from 'sonner'

import * as S from './styles'

import { useAuthStore } from '@/app'
import { MFACodeForm, MFAType, MfaCodeFormData, useValidateMfaMutation } from '@/features/auth'
import { AccessLayout } from '@/layouts'

const items = [
  {
    label: 'Código de autenticação',
    key: 'code',
  },
]

export function CodeAuthenticationPage() {
  const [params] = useSearchParams()
  const validateMfaMutation = useValidateMfaMutation()
  const userSession = useAuthStore((state) => state.tokens.session)

  const isSMS = params.get('type') === 'sms'
  const isApp = params.get('type') === 'app'

  const username = params.get('username')

  if (!username || !userSession) {
    toast.error('Usuário não encontrado')

    return <Navigate to="/auth/login" />
  }

  function handleCodeConfirmation({ code }: MfaCodeFormData) {
    const mfaType: MFAType = isSMS ? 'SMS_MFA' : isApp ? 'SOFTWARE_TOKEN_MFA' : 'EMAIL'

    validateMfaMutation.mutate({
      username: username as string,
      mfa_code: code.trim(),
      mfa_type: mfaType,
      session: userSession as string,
    })
  }

  return (
    <AccessLayout>
      <S.Container>
        <S.Title items={items} />

        {isApp ? (
          <p>Insira o código gerado no seu aplicativo de autenticação</p>
        ) : (
          <p>Insira o código recebido {isSMS ? 'por SMS' : 'no e-mail'}</p>
        )}

        <MFACodeForm onSubmit={handleCodeConfirmation} isLoading={validateMfaMutation.isPending} />
      </S.Container>
    </AccessLayout>
  )
}
