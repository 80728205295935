import { Table as AntdTable } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  span {
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const TitleSpan = styled.span<{ $hasMultiple: boolean }>`
  color: ${({ $hasMultiple, theme }) => ($hasMultiple ? theme.colors.brand : '#434343')} !important;
`

export const TooltipData = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.brand};
  border-radius: 9999px;
`

export const Table = styled(AntdTable)`
  max-height: 300px;
  overflow-y: auto;
`
